import { Row } from 'tinybase';

import { AnalysisSession, AnalysisSnapshot } from '../visynApi';

export enum TinybaseStoreKeys {
  AnalysisSessions = 'analysis-sessions',
  StateSnapshots = 'state-snapshots',
  SessionStateSnapshots = 'session-state-snapshots',
}

/**
 * Locally the snapshot is stored as a string, but the server expects a JSON object
 */
export type LocalAnalysisSnapshot = Omit<AnalysisSnapshot, 'snapshot'> & { snapshot: string };

export type LocalAnalysisSession = Omit<AnalysisSession, 'snapshots'> & { snapshots: LocalAnalysisSnapshot[]; lastOpenedOn: number };

export type ArrayAsString = `[${string}]`;

export const SessionToRowMapper = (session: Omit<AnalysisSession, 'currentSnapshot' | 'snapshots'>): Row => {
  return {
    ...session,
    collabs: JSON.stringify(session.collabs) as ArrayAsString,
    snapshots: null, // NOT IMPLEMENTED
  };
};

export const RowToSessionMapper = (row: Row): Omit<AnalysisSession, 'snapshots'> => ({
  id: row.id as string,
  name: row.name as string,
  changedBy: row.changedBy as string,
  changedOn: row.changedOn as string,
  createdBy: row.createdBy as string,
  createdOn: row.createdOn as string,
  description: row.description as string,
  selectedSnapshot: (row.selectedSnapshot as string) || null,
  isSharedSession: row.isSharedSession as boolean,
  public: row.public as boolean,
  appName: row.appName as string,
  currentSnapshot: row.currentSnapshot as string,
  collabs: row.collabs ? (JSON.parse?.(row.collabs as ArrayAsString) as string[]) : [],
});
