import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UiConfig, VersionResponse, reprovisynApi } from './reprovisynApi';
import { visynApi } from './visynApi';

export interface IAppState {
  ready: boolean;
  /**
   * Map for the colors which are assigned to each entity. Derived from the config file.
   * Keys are the entity id matching IWorkbench.entityId.
   * Values are any typical string representation of a color.
   */
  colorMap: Record<string, string>;
  version?: VersionResponse;
  uiConfig: UiConfig;
  isRegistrySynced: boolean;
}

const initialState: IAppState = {
  ready: false,
  isRegistrySynced: false,
  colorMap: {},
  version: null,
  uiConfig: {},
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReady(state, action: PayloadAction<boolean>) {
      state.ready = action.payload;
    },
    setVersion(state, action: PayloadAction<VersionResponse>) {
      state.version = action.payload;
    },
    resetAppStore: (state) => {
      // we reset the store only when the user logs out. The version is independent of the user.
      state.ready = false;
    },
    setIsRegistrySynced(state, action: PayloadAction<boolean>) {
      state.isRegistrySynced = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(reprovisynApi.endpoints.getApiV1ReprovisynAppConfigVersion.matchFulfilled, (state, { payload }) => {
      state.version = payload;
    });
    builder.addMatcher(reprovisynApi.endpoints.getApiV1ReprovisynAppConfigUi.matchFulfilled, (state, { payload: config }) => {
      state.uiConfig = config;
    });
    builder.addMatcher(visynApi.endpoints.entityPostApiUploadUuidPost.matchFulfilled, (state) => {
      state.isRegistrySynced = false;
    });
    builder.addMatcher(reprovisynApi.endpoints.getApiV1ReprovisynEntities.matchFulfilled, (state, { payload: entities }) => {
      const colorMap = entities
        .filter((e) => e.color)
        .reduce(
          (acc, curr) => ({
            ...acc,
            ...{
              [curr.id]: curr.color, // add the color by entity id for Ordino
              [curr.name]: curr.color, // add the color by entity name for Aevidence campaign & insights
            },
          }),
          {},
        );
      state.colorMap = colorMap;
    });
  },
});

export const { setReady, setVersion, resetAppStore, setIsRegistrySynced } = appSlice.actions;

export const appReducer = appSlice.reducer;
