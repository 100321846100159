/* eslint-disable @typescript-eslint/no-explicit-any */
import { KEY_PREFIX } from './constants';
import type { PersistConfig } from './types';

function warnIfRemoveError(err: Error) {
  if (err && process.env.NODE_ENV !== 'production') {
    console.error('redux-persist/purgeStoredState: Error purging data stored state', err);
  }
}

export default function purgeStoredState(config: PersistConfig<any>): any {
  const { storage } = config;
  const storageKey = `${KEY_PREFIX}${config.key}`;
  return storage.removeItem(storageKey, warnIfRemoveError);
}
