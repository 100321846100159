import './robots.txt';

import * as React from 'react';
import { Provider } from 'react-redux';

import { MantineProvider } from '@mantine/core';
import { createRoot } from 'react-dom/client';
import { DEFAULT_MANTINE_PROVIDER_PROPS } from 'visyn_core/app';

import { HomePage } from './pages';
import { store } from './store';

createRoot(document.querySelector('#welcome')).render(
  <Provider store={store}>
    <MantineProvider forceColorScheme="light" {...DEFAULT_MANTINE_PROVIDER_PROPS}>
      <HomePage />
    </MantineProvider>
  </Provider>,
);
