import { faAt, faBook, faBookOpen, faFaceSmile, faNewspaper } from '@fortawesome/free-solid-svg-icons';

export const navigationItems = [
  {
    id: 'news',
    title: `What's new?`,
    url: '/pages/news',
    icon: faNewspaper,
  },
  {
    id: 'docs',
    title: 'Documentation',
    url: '/pages/docs',
    icon: faBook,
  },
  {
    id: 'publications',
    title: 'Publications',
    url: '/pages/publications',
    icon: faBookOpen,
  },
];

export const footerItems = [
  ...navigationItems,
  {
    id: 'contact',
    title: 'Contact',
    url: '/pages/contact',
    icon: faAt,
  },
  {
    id: 'terms-of-use',
    title: 'Terms of use',
    url: '/pages/terms-of-use',
    icon: faFaceSmile,
  },
];
