import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IMenuState {
  activeTab: EStartMenuTab;
  mode: EStartMenuMode;
}

export enum EStartMenuMode {
  /**
   * no analysis in the background, the start menu cannot be closed
   */
  START = 'start',

  /**
   * an analysis in the background, the start menu can be closed
   */
  OVERLAY = 'overlay',
}

export enum EStartMenuTab {
  DATASET = 'dataset',
  ANALYSIS_SESSIONS = 'analysisSessions',
  CAMPAIGNS = 'campaigns',
  NONE = 'none',
}

const initialState: IMenuState = {
  activeTab: EStartMenuTab.NONE,
  mode: EStartMenuMode.START,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<EStartMenuTab>) {
      state.activeTab = action.payload;
    },
    setMode(state, action: PayloadAction<EStartMenuMode>) {
      state.mode = action.payload;
    },
    resetMenuStore: () => initialState,
  },
});

export const { setActiveTab, setMode, resetMenuStore } = menuSlice.actions;

export const menuReducer = menuSlice.reducer;
