import * as React from 'react';

import { css, cx } from '@emotion/css';
import '@mantine/carousel/styles.css';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel, Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import { Image } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import Autoplay from 'embla-carousel-autoplay';

import carousel_celllines from '../../assets/pages/home/carousel_celllines.png';
import carousel_genes from '../../assets/pages/home/carousel_genes.png';
import carousel_tissues from '../../assets/pages/home/carousel_tissues.png';
import { WELCOME_PAGE_BREAKPOINT_SMALL } from '../constants';

export function FeaturesCarousel() {
  const autoplay = React.useRef(Autoplay({ delay: 5000 }));
  const largerThanSmallScreenSize = useMediaQuery(`(min-width: ${WELCOME_PAGE_BREAKPOINT_SMALL})`);
  const [embla, setEmbla] = React.useState<Embla | null>(null);

  useAnimationOffsetEffect(embla, 0);

  return (
    <Carousel
      getEmblaApi={setEmbla}
      slideSize="75%"
      slideGap="0px"
      loop
      initialSlide={0}
      plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
      nextControlIcon={<FontAwesomeIcon icon={faChevronRight} color="#99A1A9" size={largerThanSmallScreenSize ? 'lg' : 'sm'} />}
      previousControlIcon={<FontAwesomeIcon icon={faChevronLeft} color="#99A1A9" size={largerThanSmallScreenSize ? 'lg' : 'sm'} />}
      className={cx(css`
        .mantine-Carousel-control {
          min-width: ${largerThanSmallScreenSize ? '45px' : '30px'};
          min-height: ${largerThanSmallScreenSize ? '45px' : '30px'};
        }
      `)}
    >
      <Carousel.Slide>
        <Image src={carousel_celllines} />
      </Carousel.Slide>
      <Carousel.Slide>
        <Image src={carousel_genes} />
      </Carousel.Slide>
      <Carousel.Slide>
        <Image src={carousel_tissues} />
      </Carousel.Slide>
    </Carousel>
  );
}
