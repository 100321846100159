import React from 'react';

import { Group, Text } from '@mantine/core';
import { i18n } from 'visyn_core/i18n';

import { TrialProgressRing } from './TrialProgressRing';
import { useAppSelector } from '../../hooks/useAppSelector';

export function TrialInfoIndicator() {
  const appVersionInfo = useAppSelector((state) => state.app.version);
  const trialPeriodExpired = appVersionInfo?.isTrial && appVersionInfo?.daysLeft <= 0;

  return appVersionInfo && appVersionInfo.isTrial && !trialPeriodExpired ? (
    <Group mr="xs" gap={3} style={{ paddingLeft: 0 }} data-testid="ordino-trial-period-info" color="red">
      <TrialProgressRing appVersionInfo={appVersionInfo} />
      <Text fw={500} color="white">
        {i18n.t('reprovisyn:license.trialPeriodInfo', {
          count: appVersionInfo.daysLeft,
        })}
      </Text>
    </Group>
  ) : null;
}
