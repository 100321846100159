import React from 'react';

import { BackgroundImage, Button, Container, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import ordinoHeroBackground from '../../assets/pages/home/ordino-hero-background-2.png';
import { OrdinoLogo } from '../../components/OrdinoLogo';
import { WELCOME_PAGE_BREAKPOINT_MEDIUM } from '../constants';
import { GettingStarted } from './GettingStarted';
import { WelcomeMessageBanner } from './WelcomeMessageBanner';

export function OrdinoHero() {
  const largerThanMediumScreenSize = useMediaQuery(`(min-width: ${WELCOME_PAGE_BREAKPOINT_MEDIUM})`);

  return (
    <BackgroundImage
      src={ordinoHeroBackground}
      style={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
      display="flex"
    >
      <Container
        size={largerThanMediumScreenSize ? 'xl' : '80%'}
        pt="60px"
        style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: largerThanMediumScreenSize ? '50px' : '15px' }}
      >
        <OrdinoLogo showByDatavisyn colored height={largerThanMediumScreenSize ? '11vh' : '10vh'} />
        <Text
          size={largerThanMediumScreenSize ? '1.4rem' : '1.2rem'}
          lh={largerThanMediumScreenSize ? '2.2rem' : 'lg'}
          fw={500}
          ta="center"
          c="dvGray.9"
          mb="sm"
        >
          Ordino is an interactive visual data analysis solution, empowering you to effortlessly rank, filter, and explore a diverse range of experimental and
          metadata, all in one flexible platform.
        </Text>
      </Container>
      <WelcomeMessageBanner />
      <Container style={{ justifyContent: 'space-between' }} mt={largerThanMediumScreenSize ? 'xl' : '2%'} size="90%" maw={2200} display="flex" h="100%">
        <GettingStarted />
      </Container>
      <Button
        component="a"
        href="/app"
        variant="gradient"
        gradient={{ from: 'dvPrimary.5', to: 'dvPrimary.6', deg: 150 }}
        size={largerThanMediumScreenSize ? 'xl' : 'md'}
        fw={400}
        mt="3rem"
        mb="1rem"
        mx="auto"
        style={{ boxShadow: '0 0 40px #A8D7FF' }}
      >
        START ANALYSIS
      </Button>
    </BackgroundImage>
  );
}
