import React from 'react';
import Markdown from 'react-markdown';

import gfm from 'remark-gfm';

/**
 * Render all links in markdown as target="_blank" rel="noreferrer" (new tab)
 */
function LinkRenderer(props: { href: string; children: React.ReactNode; color?: string; wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word' }) {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
      data-testid="entity-description-link"
      style={{ wordBreak: props.wordBreak || null, color: props.color || null }}
    >
      {props.children}
    </a>
  );
}

export function MarkdownText({
  children,
  color,
  wordBreak,
}: {
  children: string;
  color?: string;
  wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word';
}) {
  return (
    <Markdown
      remarkPlugins={[gfm]}
      unwrapDisallowed
      allowElement={(el) => ['a', 'br', 'code', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'italic', 'li', 'ol', 'p', 'strong', 'ul'].includes(el.tagName)}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        a: (props) => (
          <LinkRenderer href={props.href} color={color} wordBreak={wordBreak}>
            {props.children}
          </LinkRenderer>
        ),
      }}
    >
      {children}
    </Markdown>
  );
}
