import * as React from 'react';

import { Alert, Button, Container, Group, JsonInput, Spoiler, Stack, Text } from '@mantine/core';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { i18n } from 'visyn_core/i18n';

import { RTKError } from '../../store/storeUtils';

export function ErrorMessage({
  title,
  error,
  onRetry,
}: {
  title: string;
  error: RTKError | FetchBaseQueryError | SerializedError | Error;
  onRetry?: () => void;
}) {
  return error ? (
    <Container size="md" data-testid="ordino-error-message">
      <Alert px="md" icon={<i className="fa-solid fa-circle-exclamation" />} title={title} color="red">
        <Stack>
          {error instanceof RTKError ? <Text>{error.message}</Text> : error instanceof Error ? <Text>{error.message}</Text> : null}
          <Spoiler maxHeight={0} showLabel={i18n.t('reprovisyn:message.showMore')} hideLabel={i18n.t('reprovisyn:message.hide')}>
            {error instanceof RTKError ? (
              <Stack>
                <Text>
                  {i18n.t([`reprovisyn:reprovisynError.${error.code}`, 'reprovisyn:reprovisynError.unspecified'], {
                    reason: { status: error.code, statusText: error.message },
                  })}
                </Text>
                <Text>{i18n.t('reprovisyn:reprovisynError.endpoint', { endpoint: error.endpointName })}</Text>
                <JsonInput label={i18n.t('reprovisyn:reprovisynError.requestArgs')} autosize minRows={4} value={error.originalArgs} variant="filled" />
              </Stack>
            ) : (
              <JsonInput label={i18n.t('reprovisyn:reprovisynError.backendResponse')} autosize minRows={4} value={JSON.stringify(error)} variant="filled" />
            )}
          </Spoiler>
          {onRetry ? (
            <Group justify="flex-end" py="xs">
              <Button onClick={() => onRetry?.()} color="red" variant="subtle">
                &nbsp;{i18n.t('reprovisyn:app.tryAgain')}&nbsp;
              </Button>
            </Group>
          ) : null}
        </Stack>
      </Alert>
    </Container>
  ) : null;
}
