import * as React from 'react';

import { Alert, Text } from '@mantine/core';

export function InfoMessage({ info, dataTestId = 'ordino-info-message' }: { info: string | null; dataTestId?: string }) {
  return info ? (
    <Alert px="md" icon={<i className="fa-solid fa-circle-info" />} color="dvPrimary" data-testid={dataTestId}>
      <Text>{info}</Text>
    </Alert>
  ) : null;
}
