import { PluginRegistry } from 'visyn_core/plugin';

import reg from './phovea';
import tdp_core_reg from './tdp_core/phovea';

/**
 * build a registry by registering all phovea modules
 */

// other modules
import 'visyn_pro/phovea_registry';
import 'aevidence/phovea_registry';

// self
PluginRegistry.getInstance().register('tdp_core', tdp_core_reg);
PluginRegistry.getInstance().register('reprovisyn', reg);
