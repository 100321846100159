/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_VERSION } from './constants';
import type { MigrationManifest, PersistedState } from './types';

export function createMigrate(
  migrations: MigrationManifest,
  config?: { debug: boolean },
): (state: PersistedState, currentVersion: number) => Promise<PersistedState> {
  const { debug } = config || {};
  return (state: PersistedState, currentVersion: number): Promise<PersistedState> => {
    if (!state) {
      if (process.env.NODE_ENV !== 'production' && debug) {
        console.log('persist: no inbound state, skipping migration');
      }
      return Promise.resolve(undefined);
    }

    const inboundVersion: number = state._persist && state._persist.version !== undefined ? state._persist.version : DEFAULT_VERSION;
    if (inboundVersion === currentVersion) {
      if (process.env.NODE_ENV !== 'production' && debug) {
        console.log('persist: versions match, noop migration');
      }
      return Promise.resolve(state);
    }
    if (inboundVersion > currentVersion) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('persist: downgrading version is not supported');
      }
      return Promise.resolve(state);
    }

    const migrationKeys = Object.keys(migrations)
      .map((ver) => parseInt(ver, 10))
      .filter((key) => currentVersion >= key && key > inboundVersion)
      .sort((a, b) => a - b);

    if (process.env.NODE_ENV !== 'production' && debug) {
      console.log('persist: migrationKeys', migrationKeys);
    }
    try {
      const migratedState: any = migrationKeys.reduce((innerState: any, versionKey) => {
        if (process.env.NODE_ENV !== 'production' && debug) {
          console.log('persist: running migration for versionKey', versionKey);
        }
        return migrations[versionKey](innerState);
      }, state);

      return Promise.resolve(migratedState);
    } catch (err) {
      return Promise.reject(err);
    }
  };
}
