import isEqual from 'lodash/isEqual';

import { deserializeSnapshot } from '../../menu/analysis-sessions/utils';
import { IOrdinoAppState } from '../interfaces';
import { IOrdinoWebStorage } from '../persist/types';
import { LocalAnalysisSession, LocalAnalysisSnapshot, TinybaseStoreKeys, analysisSessionStore, sessionPersister } from '../tinybase';

type CellType<T extends keyof LocalAnalysisSnapshot> = LocalAnalysisSnapshot[T];
type SessionCellType<T extends keyof LocalAnalysisSession> = LocalAnalysisSession[T];

const getSessionCellTyped = <T extends keyof LocalAnalysisSession>(id: string, cellId: T) =>
  analysisSessionStore.getCell(TinybaseStoreKeys.AnalysisSessions, id, cellId) as SessionCellType<T>;

const setSesionCellTyped = (id: string, cellId: keyof LocalAnalysisSession, value: string) =>
  analysisSessionStore.setCell(TinybaseStoreKeys.AnalysisSessions, id, cellId, value);

const getSnapshotCellTyped = <T extends keyof LocalAnalysisSnapshot>(id: string, cellId: T) =>
  analysisSessionStore.getCell(TinybaseStoreKeys.StateSnapshots, id, cellId) as CellType<T>;

const setSnapshotCellTyped = (id: string, cellId: keyof LocalAnalysisSnapshot, value: string) =>
  analysisSessionStore.setCell(TinybaseStoreKeys.StateSnapshots, id, cellId, value);

/**
 * This is a custom storage implementation for tinybase that allows to persist the Ordino app state as a session snapshot in the tinybase store.
 */
export const tinybasePersistStorage: IOrdinoWebStorage = {
  getItem(key: string, lastFullState?: IOrdinoAppState): Promise<string | null> {
    if (!lastFullState) {
      return Promise.resolve(null);
    }
    // key is the slice name --> this allows to store different slices in different namespaces
    const { currentSessionId } = lastFullState;
    const currentSnapshotId = getSessionCellTyped(currentSessionId, 'currentSnapshot');
    const snapshot = getSnapshotCellTyped(currentSnapshotId, 'snapshot');
    return Promise.resolve(snapshot);
  },

  async setItem(key: string, incomingSnapshot: string, lastFullState?: IOrdinoAppState): Promise<void> {
    const { currentSessionId } = lastFullState ?? { currentSessionId: '' };
    const currentSnapshotId = getSessionCellTyped(currentSessionId, 'currentSnapshot');
    const currentSnapshot = getSnapshotCellTyped(currentSnapshotId, 'snapshot');
    // skip deserialization and comparison if the current snapshot is undefined. Otherwise, we will get a JSON parse error for undefined.
    if (currentSnapshot === undefined || isEqual(deserializeSnapshot(currentSnapshot), deserializeSnapshot(incomingSnapshot))) {
      return Promise.resolve();
    }
    const changedOn = new Date().toISOString();
    // state is changing --> no selected snapshot
    setSesionCellTyped(currentSessionId, 'selectedSnapshot', '');
    setSnapshotCellTyped(currentSnapshotId, 'changedOn', changedOn);
    setSnapshotCellTyped(currentSnapshotId, 'snapshot', incomingSnapshot);
    await sessionPersister.value?.save();
    return Promise.resolve();
  },
  removeItem(key: string) {
    return Promise.resolve();
  },
  getAllKeys(cb?: any) {
    return Promise.resolve([]);
  },
};
