/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store, configureStore } from '@reduxjs/toolkit';

import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from './constants';
import { persistSliceReducer } from './internal/persistSlice';
import type { Persistor, PersistorOptions } from './types';

type BoostrappedCallback = () => any;

export function persistStore(store: Store, options?: PersistorOptions, onBootstrapped?: BoostrappedCallback): Persistor {
  const persistedStore = configureStore({
    reducer: persistSliceReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

  const register = (key: string) => {
    persistedStore.dispatch({
      type: REGISTER,
      key,
    });
  };
  let onBootstrappedCallback: BoostrappedCallback | undefined = onBootstrapped;
  const rehydrate = (key: string, payload: Record<string, unknown>, err: any) => {
    const rehydrateAction = {
      type: REHYDRATE,
      payload,
      err,
      key,
    };
    // dispatch to `store` to rehydrate and `persistor` to track result
    store.dispatch(rehydrateAction);

    persistedStore.dispatch(rehydrateAction);
    if (typeof onBootstrappedCallback === 'function' && persistedStore.getState().bootstrapped) {
      onBootstrappedCallback();
      onBootstrappedCallback = undefined;
    }
  };

  const persistor: Persistor = {
    ...persistedStore,
    purge: () => {
      const results: Array<any> = [];
      store.dispatch({
        type: PURGE,
        result: (purgeResult: any) => {
          results.push(purgeResult);
        },
      });
      return Promise.all(results);
    },
    flush: () => {
      const results: Array<any> = [];
      store.dispatch({
        type: FLUSH,
        result: (flushResult: any) => {
          results.push(flushResult);
        },
      });
      return Promise.all(results);
    },
    pause: () => {
      store.dispatch({
        type: PAUSE,
      });
    },
    persist: () => {
      store.dispatch({ type: PERSIST, register, rehydrate });
    },
  };

  if (!(options && options.manualPersist)) {
    persistor.persist();
  }

  return persistor;
}
