import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { PersistorState } from '../types';

const initialState: PersistorState = {
  registry: [],
  bootstrapped: false,
};

const persistSlice = createSlice({
  name: 'persist',
  initialState,
  reducers: {
    register(state, action: AnyAction) {
      state.registry.push(action.key);
    },
    rehydrate(state, action: AnyAction) {
      const index = state.registry.indexOf(action.key);
      state.registry.splice(index, 1);
      state.bootstrapped = state.registry.length === 0;
    },
  },
});

export const { register, rehydrate } = persistSlice.actions;

export const persistSliceReducer = persistSlice.reducer;
